
import {defineComponent, onMounted} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";


export default defineComponent({
  name: "suppliers-invoices-list",
  components: {
  },
  setup() {

    onMounted(() => {
      setCurrentPageBreadcrumbs("Tedarikçi Faturaları", ["Faturalar"]);
    });

    return {

    };
  },
});
